<template>
   <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> 
<!--  <Students />-->
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
/* import MyComputer from './components/MyComputer.vue';
import Navbar from './components/MyNavbar.vue';
import Students from './components/Students.vue';*/


export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
